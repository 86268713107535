@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
font-family: 'PolySans-MedianItalic';
font-display: swap;
src: url('assets/fonts/PolySans-MedianItalic.woff2') format('woff2'),
    url('assets/fonts/PolySans-MedianItalic.woff') format('woff');
}

@font-face {
font-family: 'PolySans-Bulky';
font-display: swap;
src: url('assets/fonts/PolySans-Bulky.woff2') format('woff2'),
    url('assets/fonts/PolySans-Bulky.woff') format('woff');
}
